/*
 * @Author: your name
 * @Date: 2022-04-20 18:38:54
 * @LastEditTime: 2022-08-19 17:45:22
 * @LastEditors: 郑yongmei 2945202112@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \myself\src\api\axios.js
 */
import axios from "axios";

// axios.js文件配置

// 自定义实例默认值
const axiosInstance = axios.create({
  //http://crm.gxb.cn/api/
  // http://newCrm.top/api/
  //baseURL: 'http://newCrm.top/api/',
  //baseURL: 'http://api.crm.gxb.cn/api/',// `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
  baseURL: 'https://crm.gxb.cn/api/',
  timeout: 20000,  // 如果请求花费了超过 `timeout` 的时间，请求将被中断
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default axiosInstance




