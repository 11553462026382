import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"
const store = new Vuex.Store({
  //长期缓存
  // plugins:[createPersistedState()], 
  state: {
    count: 0,
  },
  // 同步的方法
  mutations: {
    increment(state,num) {
      state.count = num;
    },
  },
  //计算方法
  getters: {
    filterCount: (state) => {
      state.count++;
    },
  },
  // 支持异步的方法
  actions: {
    increment({ commit }) {
      setTimeout(() => {
        commit("increment");
      }, 1000);
    },
  },
});
export default store;
