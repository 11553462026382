/*
 * @Author: 郑yongmei 2945202112@qq.com
 * @Date: 2022-08-18 11:16:00
 * @LastEditors: 郑yongmei 2945202112@qq.com
 * @LastEditTime: 2022-08-18 16:49:44
 * @FilePath: \crm_view\src\domain.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 上传文件 http://crm.gxb.cn/uploads/ or http://newCrm.top/uploads/
// http://crm.gxb.cn/api/
// http://newCrm.top/api/
const domain = 'https://api.crm.gxb.cn/api/';
const fileUrl = "http://api.crm.gxb.cn/api/file/upload";
export default {
 testUrl:domain,
 fileUrl:fileUrl
}