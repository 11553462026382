/*
 * @Author: 郑yongmei 2945202112@qq.com
 * @Date: 2022-04-20 18:38:54
 * @LastEditors: 郑yongmei 2945202112@qq.com
 * @LastEditTime: 2022-08-09 15:18:44
 * @FilePath: \myself\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/iconfont/iconfont.css'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn' 
import domain from './domain.js'
import { Toast } from "vant";

global.domain = domain;



createApp(App).use(router).use(store).use(ElementPlus, { locale }).use(Toast).mount('#web');


