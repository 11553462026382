import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { ElMessage } from "element-plus";
const routes = [
  {
    path: "/",
    redirect: "/login", //重定向
  },
  {
    path: "/login",
    name: "Login", //登录页
    meta:{title:'登录 | 钢信宝',noCache:true},
    component: () => import("../view/Login.vue"),
  },
  {
    path: "/home",
    name: "Home", //首页
    meta:{title:'首页 | 钢信宝'},
    component: () => import("../view/Home.vue"),
  },
  {
    path: "/notice",
    meta:{title:'公告知识 | 钢信宝'},
    name: "Notice", //公告知识
    component: () => import("../view/notice/Notice.vue"),
  },
  {
    path: "/notice/detail",
    meta:{title:'公告知识详情 | 钢信宝'},
    name: "NoticeDetail", //公告知识详情
    component: () => import("../view/notice/NoticeDetail.vue"),
  },
  {
    path: "/notice/release",
    meta:{title:'发布公告 | 钢信宝'},
    name: "NoticeRelease", //发布公告
    component: () => import("../view/notice/NoticeRelease.vue"),
  },
  {
    path: "/report",
    meta:{title:'工作汇报 | 钢信宝'},
    name: "Report", //工作汇报
    component: () => import("../view/report/Report.vue"),
  },
  {
    path: "/report/detail",
    meta:{title:'工作汇报详情 | 钢信宝'},
    name: "ReportDetail", //工作汇报详情
    component: () => import("../view/report/ReportDetail.vue"),
  },
  {
    path: "/report/release",
    meta:{title:'发布工作汇报 | 钢信宝'},
    name: "ReportRelease", //发布工作汇报
    component: () => import("../view/report/ReportRelease.vue"),
  },
  {
    path: "/client",
    name: "Client", //客户列表
    meta:{title:'客户列表 | 钢信宝'},
    component: () => import("../view/clientList/Client.vue"),
  },
  {
    path: "/client/WarehouseIn",
    name: "Client/WarehouseIn", 
    meta:{title:' 已入库客户 | 钢信宝'},
    component: () => import("../view/clientList/WarehouseIn.vue"),
  },
  {
    path: "/client/NotInStorage",
    name: "Client/NotInStorage", 
    meta:{title:'待入库客户 | 钢信宝'},
    component: () => import("../view/clientList/NotInStorage.vue"),
  },
  {
    path: "/client/detail",
    name: "ClientDetail", //客户列表详情
    meta:{title:'客户列表详情 | 钢信宝'},
    component: () => import("../view/clientList/ClientDetail.vue"),
  },
  {
    path: "/client/release",
    name: "ClientRelease", //发布客户列表
    meta:{title:'发布客户列表 | 钢信宝'},
    component: () => import("../view/clientList/ClientRelease.vue"),
  },
  {
    path: "/track",
    name: "Track", //跟踪记录
    meta:{title:'跟踪记录 | 钢信宝'},
    component: () => import("../view/track/Track.vue"),
  },
  {
    path: "/track/detail",
    name: "TrackDetail", //跟踪记录详情
    meta:{title:'跟踪记录详情 | 钢信宝'},
    component: () => import("../view/track/TrackDetail.vue"),
  },
  {
    path: "/track/release",
    name: "TrackRelease", //跟踪记录录入
    meta:{title:'跟踪记录录入 | 钢信宝'},
    component: () => import("../view/track/TrackRelease.vue"),
  },
  {
    path: "/service",
    name: "Service", //客服记录
    meta:{title:'客服记录 | 钢信宝'},
    component: () => import("../view/service/Service.vue"),
  },
  {
    path: "/service/detail",
    name: "ServiceDetail", //客服记录详情
    meta:{title:'客服记录详情 | 钢信宝'},
    component: () => import("../view/service/ServiceDetail.vue"),
  },
  {
    path: "/service/release",
    name: "ServiceRelease", //客服记录录入
    meta:{title:'客服记录录入 | 钢信宝'},
    component: () => import("../view/service/ServiceRelease.vue"),
  },
  {
    path: "/contract",
    name: "Contract", //合同管理
    meta:{title:'合同管理 | 钢信宝'},
    component: () => import("../view/contract/Contract.vue"),
  },
  {
    path: "/contract/detail",
    name: "ContractDetail", //合同管理详情
    meta:{title:'合同管理详情 | 钢信宝'},
    component: () => import("../view/contract/ContractDetail.vue"),
  },
  {
    path: "/contract/release",
    name: "ContracRrelease", //合同发布
    meta:{title:'合同发布 | 钢信宝'},
    component: () => import("../view/contract/ContracRrelease.vue"),
  },
  {
    path: "/contract/audit",
    name: "ContractAudit", //合同审核
    meta:{title:'合同审核 | 钢信宝'},
    component: () => import("../view/contract/ContractAudit.vue"),
  },
  {
    path: "/message",
    name: "Message", //内部信息
    meta:{title:'内部信息 | 钢信宝'},
    component: () => import("../view/message/message.vue"),
  },
  {
    path: "/message/detail",
    name: "MessageDetail", //内部信息详情
    meta:{title:'内部信息详情 | 钢信宝'},
    component: () => import("../view/message/messageDetail.vue"),
  },
  {
    path: "/message/release",
    name: "MessageRrelease", //写信件
    meta:{title:'写信件 | 钢信宝'},
    component: () => import("../view/message/messageRrelease.vue"),
  },
  {
    path: "/employees",
    name: "Employees", //公司成员
    meta:{title:'公司成员 | 钢信宝'},
    component: () => import("../view/employees/Employees.vue"),
  },
  {
    path: "/employees/detail",
    name: "EmployeesDetail", //公司成员详情
    meta:{title:'公司成员详情 | 钢信宝'},
    component: () => import("../view/employees/EmployeesDetail.vue"),
  },
  // {
  //   path: "/commission",
  //   name: "Commission", //提成管理
  //   meta:{title:'提成管理 | 钢信宝'},
  //   component: () => import("../view/Commission/Commission.vue"),
  // },

  {
    path: "/records",
    name: "Records", //激活管理
    meta:{title:'激活 | 钢信宝'},
    component: () => import("../view/Records/Records.vue"),
  },
  {
    path: "/records/release",
    name: "RecordsRelease", //激活管理新增
    meta:{title:'激活 | 钢信宝'},
    component: () => import("../view/Records/RecordsRelease.vue"),
  },
  {
    path: "/authen",
    name: "Authen", // 认证管理
    meta:{title:'认证管理 | 钢信宝'},
    component: () => import("../view/Authen/Authen.vue"),
  },
  {
    path: "/code/list",
    name: "CodeList", // 收刊码管理
    meta:{title:'收刊码 | 钢信宝'},
    component:()=>import('../view/CodeCollection/CodeList.vue'),
  },
  {
    path: "/code/goods",
    name: "TakeOverGoods", // 收刊码管理
    meta:{title:'收刊记录 | 钢信宝'},
    component:()=>import('../view/CodeCollection/TakeOverGoods.vue'),
  },
  {
    path:"/trade/total",
    name:"TradeTotal",
    meta:{title:'交易统计 | 钢信宝'},
    component:()=>import('../view/TradeTotal/TradeTotalList.vue'),
  },
  {
    path:"/transfer/list",
    name:"TransferAudit",
    meta:{title:'提现审核 | 钢信宝'},
    component:()=>import('../view/Transfer/TransferAudit.vue'),
  },
  {
    path:"/order/list",
    name:"OrderList",
    meta:{title:'订单监控 | 钢信宝'},
    component:()=>import('../view/Order/Order.vue'),
  },
  {
    path:"/online/inquiry",
    name:"OnlineInquiry",
    meta:{title:"在线询价 | 钢信宝"},
    component:()=>import('../view/OnlineInquiry/OnlineInquiry.vue'),
  },
  // {
  //   path:"/deposit/list",
  //   name:"DepositList",
  //   meta:{title:"定金锁价 | 钢信宝"},
  //   component:()=>import('../view/DepositList/DepositList.vue'),
  // },
  {
    path:"/deposit/list/new",
    name:"DepositListNew",
    meta:{title:"定金锁价 | 钢信宝"},
    component:()=>import('../view/DepositListNew/DepositListNew.vue'),
  },
  // {
  //   path:"/test",
  //   name:"TestList",
  //   meta:{title:"定金锁价 | 钢信宝"},
  //   component:()=>import('../view/TestList/TestList.vue'),

  // }

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token")) {
    next();
    return
  }
  if(to.name!=="Login"){
    ElMessage({
      showClose: true,
      message: "请登录",
      type: "error",
    });
    next("/login");
  }else{
    next()
  }

});
router.afterEach((to,from,next) => {
  //遍历meta改变title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  window.scrollTo(0,0);
});

export default router;
