import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import Header from "./components/Header.vue";

export default {
  setup(__props) {


return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$route.meta.noCache)
      ? (_openBlock(), _createBlock(Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}
}

}